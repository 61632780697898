import axios from '@/plugins/axios.js'

// 导出
export const callExport = (params) => axios.post('/call/export', params, { responseType: 'blob' })

// 详情
export const callQualityDetail = (params) => axios.get('/call/qualityDetail', { params })

// 搜索
export const callSearch = (params) => {
  let newParams = { ...params }
  newParams.siteId = params.siteId == '' ? null : params.siteId
  return axios.post('/call/search', newParams)
}

// 统计图(pie、bar)
export const callStatistics = (params) => axios.post('/call/statistics', params)
