<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="header">
      <div class="icon"></div>
      <div>>></div>
      <div class="info">
        <p style="font-weight: bold">{{ form.startTime | dateFormat('YYYY/MM/DD HH:mm:ss') }}</p>
        <p>{{ callQuality[form.quality] }}</p>
        <p>{{ form.duration | durationFormat }}</p>
      </div>
      <div>>></div>
      <div class="icon"></div>
    </div>
    <div class="table-detail">
      <el-row>
        <el-col :span="6"
          ><div class="td">{{ $t('callStatistics.lacalUrl') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.localUri }}</div>
        </el-col>
        <el-col :span="6"
          ><div class="td">{{ $t('callStatistics.remoteUrl') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.remoteUri }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div class="td">{{ $t('callStatistics.callerCallee') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ callType[form.callRole] }}</div>
        </el-col>
        <el-col :span="6"
          ><div class="td">{{ $t('ssite.site') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.siteName }}</div>
        </el-col>
      </el-row>
    </div>
    <p>{{ $t('callStatistics.deviceInfo') }}</p>
    <div class="table-detail">
      <el-row>
        <el-col :span="6"
          ><div class="td">{{ $t('callStatistics.macAddress') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.macAddress }}</div>
        </el-col>
        <el-col :span="6"
          ><div class="td">{{ $t('device.model') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.deviceModel }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div class="td">{{ $t('device.firmware') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.firmware }}</div>
        </el-col>
        <el-col :span="6"
          ><div class="td">{{ $t('callStatistics.ipAddress') }}</div></el-col
        >
        <el-col :span="6">
          <div class="td">{{ form.privateIp }}</div>
        </el-col>
      </el-row>
    </div>
    <p>{{ $t('callStatistics.audioInfo') }}</p>
    <el-radio-group v-model="bound" size="small" style="margin-bottom: 10px;">
      <el-radio-button label="inbound">{{ $t('callStatistics.inbound') }}</el-radio-button>
      <el-radio-button label="outbound">{{ $t('callStatistics.outbound') }}</el-radio-button>
    </el-radio-group>
    <div class="table-detail">
      <el-row>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.averageJitter') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].averageJitter }}</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.maxJitter') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].maxJitter }}</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.minJitter') }}</div></el-col
        >
        <el-col :span="4"><div class="td">No parameters</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.averageDelay') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].averageDelay }}</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.maxDelay') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].maxDelay }}</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.minDelay') }}</div></el-col
        >
        <el-col :span="4"><div class="td">No parameters</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.averageLossRate') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].averageLostRate }}%</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.maxLossRate') }}</div></el-col
        >
        <el-col :span="4">
          <div class="td">{{ form[bound].maxLostRate }}%</div>
        </el-col>
        <el-col :span="4"
          ><div class="td">{{ $t('callStatistics.codec') }}</div></el-col
        >
        <el-col :span="4"><div class="td">No parameters</div></el-col>
      </el-row>
    </div>
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { callQualityDetail } from '@/api/callStatistics'

export default {
  name: 'dailog-call-detail',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('callStatistics.callQualityDetail')
      },
    },
    req: Object,
  },
  data() {
    return {
      bound: 'inbound',
      form: {
        startTime: '',
        quality: 0,
        duration: '',
        localUri: ' ',
        remoteUri: ' ',
        callRole: 1,
        siteName: ' ',
        macAddress: ' ',
        deviceModel: ' ',
        firmware: ' ',
        privateIp: ' ',
        inbound: {},
        outbound: {},
      },
    }
  },
  computed: {
    ...mapGetters('dist', ['callQuality', 'callType']),
  },
  created() {
    this.getData(this.req.id)
  },
  methods: {
    getData(id) {
      callQualityDetail({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0]
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  .icon {
    width: 60px;
    height: 60px;
    background-color: #C6C6C6;
    border-radius: 5px;
  }
}
.table-detail {
  border: 1px solid lightgray;
  border-top: 0;
  border-left: 0;
  .td {
    border: 1px solid lightgray;
    border-right: 0;
    border-bottom: 0;
    padding: 10px;
  }
  .el-col:nth-child(2n + 1) > .td {
    background-color: #ececec;
  }
}
</style>