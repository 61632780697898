<template>
  <div class="page">
    <div class="page-main">
      <div class="page-card">
        <div class="page-tools">
          <div>
            {{ $t('callStatistics.callStatistics') }}
            <el-popover placement="right-start" width="500" trigger="hover">
              <div v-html="$t('callStatistics.callQualityTip')"></div>
              <i class="el-icon-warning" slot="reference"></i>
            </el-popover>
          </div>
          <div class="tools">
            <el-radio-group
              class="radio-group"
              v-model="search.type"
              @change="getData"
            >
              <el-radio-button label="hour">{{ $t('hour') }}</el-radio-button>
              <el-radio-button label="day">{{ $t('day') }}</el-radio-button>
              <el-radio-button label="week">{{ $t('week') }}</el-radio-button>
            </el-radio-group>
            <select-time
              :startTime="search.beginTime"
              :endTime="search.endTime"
              @change="changeTime"
            />
          </div>
        </div>
        <el-row class="chart">
          <el-col :span="8">
            <echart-pie
              height="260px"
              :modelColor="histogramOption.color"
              v-model="pieData"
              @echartEvents="pieEvents"
            ></echart-pie>
          </el-col>
          <el-col :span="16">
            <ve-histogram
              :data="histogramData"
              :extend="histogramOption"
              :events="histogramEvents"
              height="260px"
            ></ve-histogram>
          </el-col>
        </el-row>
      </div>
      <div class="data-table">
        <div class="title">{{ $t('callStatistics.callQualityList') }}</div>
        <el-form :inline="true" :model="tabelSearch">
          <div class="page-tools">
            <el-form-item v-has="'export'">
              <el-button
                class="footer-btn"
                type="primary"
                icon="el-icon- iconfont icon-export"
                @click="exportItem"
              >{{ $t('export') }}</el-button>
            </el-form-item>
            <el-form-item class="page-tools-right" v-has="'list'">
              <el-button type="text" class="btn-p0" @click="filter = !filter">
                {{ $t('filter') }}
                <i :class="filter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
              </el-button>
            </el-form-item>
            <el-form-item class="page-tools-right keywords" v-has="'list'">
              <el-input
                v-model="tabelSearch.keywords"
                size="small"
                :placeholder="$t('callStatistics.keywords')"
                prefix-icon="el-icon-search"
                clearable
                @change="getTabelData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="page-tools" v-show="filter" v-has="'list'">
            <el-form-item class="page-tools-right">
              <dist-select
                v-model="tabelSearch.callRole"
                size="small"
                :optionData="callType"
                :selectAll="{ label: $t('callStatistics.allCall'), value: null }"
                @change="getTabelData"
              ></dist-select>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <select-model
                v-model="tabelSearch.model"
                size="small"
                selectAll
                @change="getTabelData"
              ></select-model>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <dist-select
                v-model="tabelSearch.quality"
                size="small"
                :optionData="callQuality"
                :selectAll="{ label: $t('callStatistics.allQuality'), value: null }"
                @change="getTabelData"
              ></dist-select>
            </el-form-item>
          </div>
        </el-form>
        <table-pagination
          memory="sipServer"
          ref="refTable"
          :tableData="tableData"
          :columnData="columnData"
          optionShow
          columnOptionShow
          :total="tabelTotal"
          :currentPage.sync="tabelSearch.currentPage"
          :pageSize.sync="tabelSearch.pageSize"
          @changeCurrentPage="getTabelData"
        >
          <template slot="quality" slot-scope="scope">
            <span
              :class="{
                'text-success': scope.row.quality == 0,
                'text-warning': scope.row.quality == 1,
                'text-danger': scope.row.quality == 2,
              }"
              ><i class="el-icon-s-opportunity"></i>{{ callQuality[scope.row.quality] }}
            </span>
          </template>
          <!-- option -->
          <template slot="option" slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="$t('callStatistics.callDetail')"
              placement="top"
              :open-delay="1000"
            >
              <el-button type="text" class="btn-p0" @click="callDetailItem(scope.row.id)">
                <i class="el-icon-document"></i>
              </el-button>
            </el-tooltip>
          </template>
        </table-pagination>
      </div>
    </div>
    <dailog-call-detail
      v-if="callDetailVisible"
      :visible.sync="callDetailVisible"
      :req="callDetailReq"
    ></dailog-call-detail>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { callStatistics, callSearch, callExport } from '@/api/callStatistics'
import { TablePagination, SelectModel, DistSelect, SelectTime } from '@/components'
import { downloadFile } from '@/plugins/methods'
import DailogCallDetail from './dailog-call-detail'
import VeHistogram from 'v-charts/lib/histogram.common'
import EchartPie from '@/views/Home/echart-pie'

export default {
  name: 'CallStatistics',
  components: {
    VeHistogram,
    TablePagination,
    SelectModel,
    DistSelect,
    DailogCallDetail,
    EchartPie,
    SelectTime
  },
  data() {
    const endTime = new Date(new Date().toLocaleDateString()).getTime()
    const beginTime = endTime - 3600 * 1000 * 24 * 30
    return {
      search: {
        beginTime,
        endTime,
        type: 'day',
      },
      // pie
      pieData: [],
      // histogram
      histogramData: {
        columns: ['name', 'Good', 'Fair', 'Poor'],
        rows: [],
      },
      histogramOption: {
        color: ['#B7F4EC', '#AFC5FD', '#FED691'],
        yAxis: {
          minInterval: 1,
        },
      },
      histogramEvents: {
        click: (item) => {
          console.log('echartEvents', item)
        },
      },
      // table
      filter: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'startTime',
        sortOrder: 'desc',
        keywords: null,
        model: null,
        quality: null,
        callRole: null,
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('callStatistics.callQuality'),
          prop: 'quality',
          minWidth: 120,
          slot: true,
        },
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 160,
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 140,
        },
        {
          label: this.$t('device.model'),
          prop: 'deviceModel',
          minWidth: 100,
        },
        {
          label: this.$t('device.site'),
          prop: 'siteName',
          minWidth: 100,
        },
        {
          label: this.$t('device.ip'),
          prop: 'privateIp',
          minWidth: 100,
        },
        {
          label: this.$t('device.firmware'),
          prop: 'firmware',
          minWidth: 100,
        },
        {
          label: this.$t('callStatistics.callerCallee'),
          prop: 'callRole',
          minWidth: 140,
          filter: (val) => this.callType[val],
        },
        {
          label: this.$t('sidebar.sipAccount'),
          prop: 'ip',
          minWidth: 130,
        },
        {
          label: this.$t('callStatistics.time'),
          prop: 'startTime',
          minWidth: 160,
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      // 详情
      callDetailVisible: false,
      callDetailReq: {},
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
    ...mapGetters('dist', ['callQuality', 'callType']),
    tabelParams() {
      let data = { ...this.tabelSearch }
      data.sites = this.sidebarSite
      return data
    },
    searchParams() {
      let data = { ...this.search }
      data.site = this.sidebarSite
      return data
    },
    // datePicker: {
    //   get() {
    //     if (this.search.beginTime && this.search.endTime) {
    //       return [this.search.beginTime, this.search.endTime]
    //     } else {
    //       return ''
    //     }
    //   },
    //   set(val) {
    //     this.search.beginTime = val ? val[0] : ''
    //     this.search.endTime = val ? val[1] : ''
    //   },
    // },
  },
  watch: {
    'tabelParams.sites'() {
      this.getTabelData()
    },
  },
  created() {
    this.getData()
    this.getTabelData()
  },
  methods: {
    changeTime(event) {
      this.search.beginTime = event[0] || null
      this.search.endTime = event[1] || null
      this.getData()
    },
    getData() {
      callStatistics(this.searchParams)
        .then(({ data }) => {
          const { pie, bar } = data.result.rows[0]
          // pie
          this.pieData = [
            { name: this.$t('dist.callQuality.good'), value: pie.good },
            { name: this.$t('dist.callQuality.fair'), value: pie.fair },
            { name: this.$t('dist.callQuality.poor'), value: pie.bad },
          ]
          // histogram
          let rows = []
          Object.entries(bar.good).forEach((e) => {
            rows.push({
              name: e[0],
              Good: e[1],
              Fair: bar.fair[e[0]],
              Poor: bar.bad[e[0]],
            })
          })
          this.histogramData.rows = rows
        })
        .catch(() => {})
    },
    getTabelData() {
      callSearch(this.tabelParams)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
        })
        .catch(() => {})
    },
    exportItem() {
      callExport(this.tabelParams).then((res) => {
        console.log(res)
        downloadFile(res)
      })
    },
    callDetailItem(id) {
      this.callDetailVisible = true
      this.callDetailReq = { id }
    },
    pieEvents(item) {
      console.log(item)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.el-input--small .el-input__inner {
  $btn-height: 44px;
  height: $btn-height;
  line-height: $btn-height;
  width: 190px;
}
::v-deep.el-radio-button__inner,
::v-deep.el-radio-button:first-child .el-radio-button__inner {
  padding: 14px 20px;
  border-color: #C6C6C6;
}
.page .page-main {
  .page-card {
    padding-bottom: 0;
    .page-tools {
      &:after {
        content: none;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      .tools {
        display: flex;
        align-items: center;
        .radio-group {
          margin-right: 10px;
        }
      }
    }
    .chart {
      margin-top: 10px;
    }
  }
  .data-table {
    margin-top: 30px;
    .title {
      margin-left: 30px;
      font-size: 20px;
      color: #333;
    }
  }
}
</style>
